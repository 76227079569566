export const environment = {
  isReleaseBuild: false,
  envName:'Project One',
  isDev:true,
  enableDebug: false,
  useCache:false,
  useProxy:true,
  baseApiUrl:'/Services/MessengerProjOne/',
  host:'https://webservicesmo.energytransfer.com',
  port:':80',
  secondaryUrl:'https://webservicesmo.energytransfer.com',
  infoPostBaseUrl: 'https://projectone.peplmessenger.energytransfer.com/project1/ipost',
  extSecurityUrl: 'https://mo.security.energytransfer.net/'
};
